<template>
  <div class="topbar" :class="{ 'header--sticky': checkSticky }">
    <div class="topbar-container mobile-only">
      <i>
        <SfIcon
          :color="message.icon.color"
          :size="message.icon.size"
          :icon="message.icon.type.mail"
        />
      </i>
      <a :href="'mailto: ' + message.mail">
        {{ message.mail }}
      </a>
    </div>
    <div class="topbar-container desktop-only">
      <p class="topbar-title">
        {{ message.title.desktop }}
      </p>
      <i>
        <SfIcon
          :color="message.icon.color"
          :size="message.icon.size"
          :icon="message.icon.type.mail"
        />
      </i>
      <a :href="'mailto: ' + message.mail">
        {{ message.mail }}
      </a>
    </div>
  </div>
</template>

<script>
import { useContent } from '@gemini-vsf/composables';
import { SfIcon } from '@storefront-ui/vue';
import { useI18n } from '~/helpers/hooks/usei18n';

export default {
  components: {
    SfIcon,
  },
  props: {
    checkSticky: Boolean,
    isHome: Boolean,
  },
  setup() {
    const { locale } = useI18n();
    const { blocks } = useContent(`cmsBlockFooter-${locale}`);
    const preHeaderMessage = `pre-header-${locale}`;

    const message = {
      title: {
        desktop: 'Servizio clienti',
      },
      mail: 'assistenza-clienti@bestdigit.it',
      phone: '+39069456060',
      icon: {
        color: 'var(--c-primary)',
        size: 'xs',
        type: {
          tel: 'phone',
          mail: 'mail',
          box: 'shipping',
        },
      },
    };

    return {
      blocks,
      preHeaderMessage,
      message,
    };
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  background: var(--c-grey-1);
  width: 100vw;
  top: 0;
  z-index: 5;
  .topbar-container {
    height: 30px;
    position: relative;
    transition: all 100ms;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: var(--spacer-2xl);

    .topbar-title {
      font-weight: var(--font-weight--bold);
    }

    p,
    a {
      color: var(--c-grey-4);
      font-size: var(--font-size--5xs);
      font-family: var(--font-family-secondary);
      font-weight: var(--font-weight--norm);
    }

    i {
      margin: 0 6px 0 15px;
    }

    a {
      text-decoration: none;
    }

    &__button {
      margin: 0 0 0 var(--spacer-xs);
    }
  }

  &.header--sticky {
    // transform: translateY(-200px);
    position: fixed;
  }
}

@media (max-width: $tablet-max) {
  .topbar {
    .topbar-container {
      min-height: unset;
      max-height: 30px;
      justify-content: center;
      margin-right: 0;
      //overflow: auto;
      i {
        margin-left: 0;
      }
    }

    .desktop-only {
      display: none;
    }
  }
}

@media (max-width: $mobile-max) {
  .topbar {
    .topbar-container {
      .topbar-title {
        white-space: nowrap;
      }
      p a {
        width: 100%;
        display: block;
      }
    }
  }
}
@media (min-width: $desktop-min) {
  .topbar {
    .topbar-container {
      .topbar-title {
        text-transform: uppercase;
        margin-right: 15px;
      }
    }
    .mobile-only {
      display: none;
    }
  }
}
</style>
