export default {
  'About us': 'About us',
  'Add to compare': 'Add to compare',
  'Add to Wishlist': 'Add to Wishlist',
  'Additional Information': 'Additional Information',
  'Allow order notifications': 'Allow order notifications',
  Apply: 'Apply',
  'Attention!': 'Attention!',
  'Back to home': 'Back to home',
  'Back to homepage': 'Back to homepage',
  'Billing address': 'Billing address',
  Brand: 'Brand',
  Cancel: 'Cancel',
  Categories: 'Categories',
  'Change password your account': 'If you want to change the password to access your account, enter the following information',
  'Clear all': 'Clear all',
  'Commercial information': 'agree to receive personalized commercial information from Brand name by email',
  'Contact details updated': 'Keep your addresses and contact details updated.',
  'Contact us': 'Contact us',
  'Continue to billing': 'Continue to billing',
  'Continue to payment': 'Continue to payment',
  'Continue to shipping': 'Continue to shipping',
  'Cookies Policy': 'Cookies Policy',
  'Create an account': 'Create an account',
  'Customer Reviews': 'Customer Reviews',
  'Customer service': 'Customer service',
  Departments: 'Departments',
  Description: 'Description',
  'Details and status orders': 'Check the details and status of your orders in the online store. You can also cancel your order or request a return.',
  Discount: 'Discount',
  Done: 'Done',
  'Download all': 'Download all',
  Download: 'Download',
  Edit: 'Edit',
  'Email address': 'Email address',
  Empty: 'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
  'Enjoy your free account': 'Enjoy these perks with your free account!',
  Feedback: 'Your feedback is important to us. Let us know what we could improve.',
  'Feel free to edit': 'On this page you can update your personal data at any time.',
  'Find out more': 'Find out more',
  'Forgot Password Modal Email': 'Email you are using to sign in:',
  'Forgot Password': 'If you can’t remember your password, you can reset it.',
  'Forgotten password?': 'Forgotten password?',
  'Go back shopping': 'Go back shopping',
  'Go back to shop': 'Go back to shop',
  'Go back': 'Go back',
  'Go to checkout': 'Go to checkout',
  Guarantee: 'Guarantee',
  'Help & FAQs': 'Help & FAQs',
  Help: 'Help',
  hide: 'hide',
  Home: 'Home',
  'I agree to': 'I agree to',
  'I confirm subscription': 'I confirm subscription',
  'Info after order':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.',
  Instruction1: 'Take care of me',
  Instruction2: 'Just here for the care instructions?',
  Instruction3: 'Yeah, we thought so',
  'It was not possible to request a new password, please check the entered email address.':
    'It was not possible to request a new password, please check the entered email address.',
  Item: 'Item',
  Items: 'Items',
  Kidswear: 'Kidswear',
  'Let’s start now – we’ll help you': 'Let’s start now – we’ll help you.',
  'Log into your account': 'Log into your account',
  'login in to your account': 'login in to your account',
  Login: 'Login',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
  'Make an order': 'Make an order',
  'Manage addresses': 'Save your frequently used addresses (home, workplace ...) for an easier check-out!',
  'Manage billing addresses':
    'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually with each order.',
  'Manage shipping addresses':
    'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually with each order.',
  'Match it with': 'Match it with',
  'Men fashion': 'Men fashion',
  'My Cart': 'My Cart',
  'No account': `Don't have an account yet?`,
  'On Hold': 'On Hold/Canceled',
  'or fill the details below': 'or fill the details below',
  or: 'or',
  'Order summary': 'Order summary',
  'Other products you might like': 'Other products you might like',
  'Password Changed': 'Password successfuly changed. You can now go back to homepage and sign in.',
  'Pay for order': 'Pay for order',
  'Payment & Delivery': 'Payment & Delivery',
  'Payment method': 'Payment method',
  'Payment methods': 'Payment methods',
  'Personal details': 'Personal details',
  'Please type your current password to change your email address.': 'Please type your current password to change your email address.',
  Price: 'Price',
  Privacy: 'Privacy',
  'Product suggestions': 'Product suggestions',
  Product: 'Product',
  'Products found': 'Products found',
  Products: 'Products',
  'Purchase terms': 'Purchase terms',
  'Quality in the details': 'Quality in the details',
  Quantity: 'Quantity',
  'Read all reviews': 'Read all reviews',
  'Read and understand': 'I have read and understand the',
  'Read reviews': 'Read reviews',
  'Register today': 'Register today',
  Register: 'Register',
  'Remove from Wishlist': 'Remove from Wishlist',
  'Reset Password': 'Reset Password',
  'Review my order': 'Review my order',
  'Same as shipping address': 'Same as shipping address',
  'Save changes': 'Save changes',
  'Save for later': 'Save for later',
  'Save Password': 'Save Password',
  'Search for items': 'Search for items',
  'Search results': 'Search results',
  'Sections that interest you': 'Sections that interest you',
  'See all results': 'See all results',
  'Select payment method': 'Select payment method',
  'Send my feedback': 'Send my feedback',
  'Set up newsletter': 'Check the privacy box and click the button below to be always updated on our news and promotions.',
  'Cancel newsletter': "Click the button below if you don't want to be updated on our news and promotions.",
  'Share your look': 'Share your look',
  'Shipping address': 'Shipping address',
  'Shipping details': 'Shipping details',
  'Shipping method': 'Shipping method',
  'show more': 'show more',
  'Show on page': 'Show on page',
  'Sign in': 'Sign in',
  'Sort by': 'Sort by',
  'Sort: Default': 'Default',
  'Sort: Name A-Z': 'Name A-Z',
  'Sort: Name Z-A': 'Name Z-A',
  'Sort: Price from high to low': 'Price from high to low',
  'Sort: Price from low to high': 'Price from low to high',
  'Sort: New arrivals': 'New arrivals',
  'Start shopping': 'Start shopping',
  'Subscribe to newsletter': 'Subscribe to newsletter',
  'Cancel subscription': 'Cancel subscription',
  Subscribe: 'Subscribe',
  Subtotal: 'Subtotal',
  'Successful placed order':
    'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  'Terms and conditions': 'Terms and conditions',
  'Thank You Inbox': 'If the message is not arriving in your inbox, try another email address you might’ve used to register.',
  'Total items': 'Total items',
  'Total price': 'Total price',
  Total: 'Total',
  'Update password': 'Update password',
  'Update personal data': 'Update now',
  'Use your personal data':
    'At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the',
  'View details': 'View details',
  View: 'View',
  'Who we are': 'Who we are',
  'Women fashion': 'Women fashion',
  'You can unsubscribe at any time': 'You can unsubscribe at any time',
  'You currently have no orders': 'You currently have no orders',
  'You haven’t searched for items yet': 'You haven’t searched for items yet.',
  'Your bag is empty': 'Your bag is empty',
  'Your current email address is': 'Your current email address is',
  forgotPasswordConfirmation:
    'Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.',
  subscribeToNewsletterModalContent:
    'After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.',
  'Do you want to contact us?': 'Do you want to contact us?',
  'Fill out the form below!': 'Fill out the form below!',
  'Come visit': 'Come visit',
  'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters.':
    'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters.',
  'Follow us and stay updated': 'Follow us and stay updated',
  'Required field': 'Required field',
  Name: 'Name',
  'Phone Number': 'Phone Number',
  Message: 'Message',
  'Send Message': 'Send Message',
  Submit: 'Submit',
  'All right reserved': 'All right reserved',
  'Cookies policy': 'Cookies policy',
  'Designed by': 'Designed by',
  Cba: 'Cba',
  'Add To Favourites': 'Add To Favourites',
  'Product description': 'Product description',
  'Do you want to be notified if the sizes will be available again?': 'Do you want to be notified if the sizes will be available again?',
  'Size specifications': 'Size specifications',
  Sizes: 'Sizes',
  'La nostra storia': 'Our history',
  'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.':
    'We are a small family business: we have strong ties with the productive fabric of our country but very international inspirations.',
  'Fatto con amore': 'Made with love',
  'in Italia.': 'in Italy.',
  'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs.':
    'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs.',
  'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio.':
    'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio.',
  'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand.':
    'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand.',
  'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability regulations.':
    'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability regulations.',
  'Worldwide delivery in 2/7 working days.': 'Worldwide delivery in 2/7 working days.',
  'Find out shipping costs': 'Find out shipping costs',
  'Thank you for your order!': 'Thank you for your order!',
  'Your Purchase': 'Your Purchase',
  'Your Account': 'Your Account',
  'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.':
    'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter.':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter.',
  'Primary contacts for any questions': 'Primary contacts for any questions',
  'Back to home page': 'Back to home page',
  'Description product': 'Description product',
  'Let yourself be guided by our advice': 'Let yourself be guided by our advice',
  'Here is a list of related garments': 'Here is a list of related garments',
  Carrello: 'Cart',
  Si: 'Yes',
  Annulla: 'Cancel',
  'Sei sicuro di voler rimuovere questo articolo dal carrello?': 'Are you sure? you want to remove this item from your cart?',
  'Acquista ': 'Buy ',
  'Torna allo shopping': 'Go back to shopping',
  'Add to cart': 'Add to cart',
  'you discover': 'you discover',
  'Made with love in Italy': 'Made with love in Italy',
  'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability.':
    'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability.',
  'Discover ours': 'Discover ours',
  'Discover our wool': 'Discover our wool',
  'Discover the new winter sweaters': 'Discover the new winter sweaters',
  'The novelty of the moment!': 'The novelty of the moment!',
  'Our recommendations': 'Our recommendations',
  Color: 'Color',
  Size: 'Italian size',
  'Sort by relevance': 'Sort by relevance',
  Filters: 'Filters',
  'Apply filters': 'Apply filters',
  'Something went wrong during form submission. Please try again later': 'Something went wrong during form submission. Please try again later',
  'User Account': 'User Account',
  Shipping: 'Shipping',
  Billing: 'Billing',
  Payment: 'Payment',
  'First name': 'First name',
  'Last name': 'Last name',
  'Street name': 'Street name',
  'House/Apartment number': 'House/Apartment number',
  'At...': 'At...',
  City: 'City',
  'Zip-code': 'Zip-code',
  'Phone number': 'Phone number',
  'Please select a country first': 'Please select a country first',
  'This field is required': 'This field is required',
  'Create an account on the store': 'Create an account on the store',
  'Continue without account': 'Continue without account',
  'Login on the store': 'Login on the store',
  'There are no shipping methods available for this country. We are sorry, please try with different country or later.':
    'There are no shipping methods available for this country. We are sorry, please try with different country or later.',
  Safety: 'Safety',
  'It carefully packaged with a personal touch': 'It carefully packaged with a personal touch',
  'Easy shipping': 'Easy shipping',
  'You’ll receive dispatch confirmation and an arrival date': 'You’ll receive dispatch confirmation and an arrival date',
  'Changed your mind?': 'Changed your mind?',
  'Rest assured, we offer free returns within 30 days': 'Rest assured, we offer free returns within 30 days',
  'Select shipping method': 'Select shipping method',
  'Copy address data from shipping': 'Copy address data from shipping',
  Amount: 'Amount',
  Search: 'Search',
  'State/Province': 'State/Province',
  Country: 'Country',
  'The field should have at least 2 characters': 'The field should have at least 2 characters',
  Monday: 'Monday',
  'Tuesday to Saturday': 'Tuesday to Saturday',
  Sunday: 'Sunday',
  'Go to cart': 'Go to cart',
  'Enter promo code': 'Enter promo code',
  'Order review': 'Order review',
  'Enter your E-mail here': 'Enter your E-mail here',
  'Subscribe to the newsletter': 'Subscribe to the newsletter',
  'Please enter a valid email address.': 'Please enter a valid email address.',
  Contacts: 'Contacts',
  Services: 'Services',
  Shops: 'Shops',
  Social: 'Social',
  Share: 'Share',
  'Add to favourites': 'Add to favourites',
  Remove: 'Remove',
  Cart: 'Cart',
  'Password change': 'Password change',
  'Privacy Policy': 'Privacy Policy',
  'Personal data': 'Personal data',
  'Order history': 'Order history',
  'Order details': 'Order details',
  'My newsletter': 'My newsletter',
  'Personal Details': 'Personal Details',
  'My profile': 'My profile',
  'My Account': 'My Account',
  'Addresses details': 'Addresses details',
  'Add new address': 'Add new address',
  Delete: 'Delete',
  Change: 'Change',
  'Add the address': 'Add the address',
  'Update the address': 'Update the address',
  'Set as default shipping': 'Set as default shipping',
  'Set as default billing': 'Set as default billing',
  'The field should have at least {length} characters': 'The field should have at least {length} characters',
  "Passwords don't match": "Passwords don't match",
  'The user account data was successfully updated!': 'The user account data was successfully updated!',
  'Order ID': 'Order ID',
  'All Orders': 'All Orders',
  'My orders': 'My orders',
  Date: 'Date',
  Status: 'Status',
  'This feature is not implemented yet! Please take a look at': 'This feature is not implemented yet! Please take a look at',
  'for our Roadmap!': 'for our Roadmap!',
  'Payment date': 'Payment date',
  'Current Password': 'Current Password',
  'Your e-mail': 'Your e-mail',
  'Repeat Password': 'Repeat Password',
  'New Password': 'New Password',
  'Invalid email': 'Invalid email',
  'Sign Up for Newsletter': 'Sign Up for Newsletter',
  'Use this address as my default one.': 'Use this address as my default one.',
  'Default Shipping Address': 'Default Shipping Address',
  'Default Billing Address': 'Default Billing Address',
  'Shipping and returns': 'Shipping and returns',
  'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout.':
    'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout.',
  'Your cart is empty': 'Your cart is empty',
  'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in.':
    'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in.',
  Password: 'Password',
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number and one special character (! # $ % & * ? @ ^ _ -)':
    'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number and one special character (! # $ % & * ? @ ^ _ -)',
  'Our story': 'Our story',
  'Your shopping cart is empty': 'Your shopping cart is empty',
  'Order No.': 'Order No.',
  'Search Results': 'Search Results',
  'Wrong user credentials': 'Wrong user credentials',
  'successfully removed from your cart': 'has been successfully removed from your cart.',
  'The size and color must be selected before adding a product to the cart!':
    'The size and color must be selected before adding a product to the cart!',
  'Out of stock': 'Out of stock',
  'Italian size': 'Italian size',
  'Size guide': 'Size guide',
  'Notify me if back in stock': 'Notify me if back in stock',
  Details: 'Details',
  'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare the shipment.':
    'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare the shipment.',
  'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries.':
    'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries.',
  'Shipping status can be verified at any time by using the tracking number provided via mail.':
    'Shipping status can be verified at any time by using the tracking number provided via mail.',
  'Would you like to discover all about payments and returns? Have a look here. ("here" linkato a pagina customer care)':
    'Would you like to discover all about payments and returns? Have a look here. ("here" linkato a pagina customer care)',
  'Get inspired We think you might love these': 'Get inspired We think you might love these',
  'Sign up for our newsletter': 'Sign up for our newsletter',
  'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.':
    'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.',
  'Re-Password': 'Re-Password',
  'Get inspired': 'Get inspired',
  'We think you might love these': 'We think you might love these',
  'Confirmation request has been sent.': 'Confirmation request has been sent.',
  'Reset filters': 'Reset filters',
  'Discover the special offers': 'Discover the special offers',
  'Best Digit special offers': 'Best Digit special offers',
  'All the': 'All the',
  'new-ins': 'new-ins',
  'Discover all new-ins': 'Discover all new-ins',
  'Invalid telephone number': 'Invalid telephone number',
  'Price Range': 'Price Range',
  'Other filters': 'Other filters',
  'Send us an email': 'Send us an email',
  'Need help?': 'Need help?',
  'Update associated services': 'Update associated services',
  'Fiscal Code': 'Fiscal Code',
  'Invalid fiscal code': 'Insert a valid fiscal code',
  'Please make sure your passwords match': 'Please make sure your passwords match',
  'Your fiscal code': 'Your fiscal code',
  'Spedizione prevista per il : ': 'Estimated delivery for : ',
  January: 'Gennaio',
  February: 'Febbraio',
  March: 'Marzo',
  April: 'Aprile',
  May: 'Maggio',
  June: 'Giugno',
  July: 'Luglio',
  August: 'Agosto',
  September: 'Settembre',
  October: 'Ottobre',
  November: 'Novembre',
  December: 'Dicembre',
  'Start pre-authorization': 'Start pre-authorization',
  'Costo spedizione non disponibile': 'Delivery cost not available',
  'Costo spedizione : ': 'Delivery cost : ',
  "Non c'è nessuna descrizione prodotto": 'There is no product description',
  'This fiscal code is not valid': 'This fiscal code is not valid',
  'Show similar products': 'Show similar products',
  'Newsletter subscription failed: data is not valid or email is already subscribed!':
    'Newsletter subscription failed: data is not valid or email is already subscribed!',
  'An error occurred during subscription delete.': 'An error occurred during subscription delete.',
  'An error occurred: cannot update newsletter status at this moment.': 'An error occurred: cannot update newsletter status at this moment.',
  'Pickup from our store': 'Pickup from our store',
  'Store pickup:': 'Store pickup:',
  'Seleziona metodo di pagamento': 'Choose payment method',
  'Spedizione ': 'Shipping ',
  Code: 'Code',
  'added to cart': 'added to cart',
  'removed from wishlist': 'removed from wishlist',
  'added to wishlist': 'added to wishlist',
  'My account': 'My account',
  'Thank you': 'Thank you',
  'Error page': 'Error page',
  'Something went wrong with the selected payment method': 'Something went wrong with the selected payment method',
  'Prodotto non disponibile': 'Product not available',
  "Cart Error: We're sorry, but associated services for all products had to be removed, please try again.":
    "Cart Error: We're sorry, but associated services for all products had to be removed, please try again.",
  "Cart Fatal Error: We're sorry, but your cart has been emptied. Please try again.":
    "Cart Fatal Error: We're sorry, but your cart has been emptied. Please try again.",
  "There's been an error removing this item. Please try again.": "There's been an error removing this item. Please try again.",
  'Add product to cart first to buy associated services.': 'Add product to cart first to buy associated services.',
  "There's been an error adding the service, please try again": "There's been an error adding the service, please try again",
  'My wishlist': 'My wishlist',
  'There is no Technical Specifications for the product': 'There is no Technical Specifications for the product',
  'Show more results': 'Show more results',
  'Loading...': 'Loading...',
  'All products loaded': 'All products loaded',
  of: 'of',
  products: 'products',
  'Load page before': 'Load page before',
  'Show only products on sale': 'Show only products on sale',
  'Spedizione standard': 'Standard shipping',
};
